import './App.css';
import ModuleLinks from './components/financial-model';
import moduleData from "./data/data";
import EduModel from './components/edu-model';
import eduData from "./data/education";

function App() {
  return (
    <div className="App">
      <header  >
      </header>
      <div className='container'>
        <div className='title'>Financial Modeling Resources</div>
    
        <div className='columns'>
          <div className='left-column'>
            <ModuleLinks modules={moduleData} />
          </div>
          <div className='right-column'>
         
    
          <EduModel items={eduData} />
      
          </div>
        </div>
      </div>
      <footer className="footer">
        &copy; {new Date().getFullYear()} Financial Modeling Info. All rights reserved.
      </footer>
    </div>
  );
}

export default App;
