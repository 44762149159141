const eduData = [
    {
        "title": "Aswath Damodaran Valuation Studies",
        "url":"https://pages.stern.nyu.edu/~adamodar/"
    },
    {
        "title": "Edward Bodmer Case Studies",
        "url":"https://edbodmer.com/key-files-for-corporate-analysis/"
    }
]
export default eduData;