import React from 'react';
import './EduModel.css'; // Import the CSS file

const EduModel = ({ items }) => {
    return (
        <div className="edu-links-container">
            <div className='resource-title'>Case Studies</div>
            {items.map((item, index) => (
                <div key={index}>
                    <a href={item.url} className="edu-link" target="_blank">
                        {item.title}
                    </a>
                    <br />
                </div>
            ))}
        </div>
    );
};

export default EduModel;