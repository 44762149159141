import React from 'react';
import './ModuleLinks.css'; // Import the CSS file

const ModuleLinks = ({ modules }) => {
    return (
        <div className="module-links-container">
             <div className='resource-title'>Test & Practices</div>
            {modules.map((module, index) => (
                <div key={index} className="module">
                    <div className='sub-title'>{module.moduleTitle}</div>
                    {module.childModules && module.childModules.map((child, childIndex) => (
                      <div key={childIndex} className="child-module">
                     <div > {child.moduleTitle}</div>
                      {child.childProblems && child.childProblems.map((problem, problemIndex) => (
                        <React.Fragment key={problemIndex}>
                        <a href={problem.url} className="problem-link" target="_blank">
                          {problem.problemTitle}
                        </a>
                        <br />
                      </React.Fragment>
                      ))}
                    </div>
                    ))}
                </div>
            ))}
        </div>
    );
};


export default ModuleLinks;